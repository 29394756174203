<template>
  <section class="favorites" style="max-width: 1570px; margin: 0 auto;">
    <h4 class="section-title mb-4" >Мои заказы</h4>
    <b-row v-if="orderList.length">
      <b-col v-for="order in orderList" :key="order.id" md="6" lg="6" xl="4" sm="12">
        <div class="profile-box text-center mb-4">
          <div class="mb-3 text-center">
            <span class="order-item__title">Заказ №{{ order.id }}</span>
          </div>
          <div class="order-details__info">
            Дата заказа:
            <span class="font-weight-bolder">{{ new Date(order.created_at).toLocaleDateString('ru-RU') }}</span>
          </div>
          <div class="order-details__info">
            Статус:
            <span class="font-weight-bold text-muted">{{ getOrderStatus(order.status) }}</span>
          </div>
          <span @click="showOrderDetails(order.id)" class="order-item__details">Посмотреть детали</span>
        </div>
      </b-col>
    </b-row>
    <div v-else class="favorites__empty text-center">Вы еще ничего не заказывали</div>
    <b-modal
      v-model="showOrderDetailsModal"
      id="order-details-modal"
      :title="`Детали заказа #${orderDetails.id}`"
      hide-footer
      centered
      class="modl"
    >
      <div class="order-details">
        <div class="order-details__info">
          Список заказа:
          <ul>
            <li v-for="item in orderDetails.items" :key="item.id">- {{ item.name }} ({{ item.price }} ₸)</li>
          </ul>
        </div>

        <div class="order-details__info">
          Дата заказа:
          <span class="font-weight-bolder">{{ new Date(orderDetails.created_at).toLocaleDateString('ru-RU') }}</span>
        </div>
        <div class="order-details__info">
          Текущий статус:
          <span class="font-weight-bolder text-info">{{ getOrderStatus(orderDetails.status) }}</span>
        </div>
        <div class="order-details__info">
          Цена доставки:
          <span class="font-weight-bolder"
            >{{ orderDetails.delivery_amount ? orderDetails.delivery_amount : 0 }} ₸</span
          >
        </div>
        <div class="order-details__info">
          Сумма заказа:
          <span class="font-weight-bolder">{{ orderDetails.sum ? orderDetails.sum : 0 }} ₸</span>
        </div>
        <div v-if="orderDetails.file" class="order-details__info mt-3">
          <b-button @click="redirectToFile(orderDetails.file)" variant="outline-secondary" size="sm">
            Скачать прикрепленный файл
          </b-button>
        </div>
        <div v-if="orderDetails.text" class="order-details__info mt-3">
          Дополнительная информация:
          <p class="mt-1">{{ orderDetails.text }}</p>
        </div>
      </div>
    </b-modal>
  </section>
</template>

<script>
import Api from '@/services/api'

export default {
  name: 'MyOrdersPage',
  data: () => ({
    orderList: [],
    orderDetails: {},
    showOrderDetailsModal: false,
  }),

  async mounted() {
    const { data } = await this.wrapAndHandleRequest(Api.ordersList, true)
    if (data && Array.isArray(data)) {
      data.reverse()
      this.orderList = data
    }
  },

  methods: {
    showOrderDetails(orderId) {
      const order = this.orderList.find((el) => el.id === orderId)
      if (order) {
        this.orderDetails = order
        this.$nextTick(() => (this.showOrderDetailsModal = true))
      }
    },

    redirectToFile(url) {
      const link = document.querySelector('.invisibleLink')
      link.setAttribute('href', url)
      link.click()
    },

    getOrderStatus(status) {
      switch (status) {
        case 1:
          return 'Создан'
        case 2:
          return 'Оплачен'
        case 3:
          return 'В ожидании оплаты'
        case 4:
          return 'Отправлен'
        case 5:
          return 'Отгружен'
        case 6:
          return 'Доставлен'
        case 7:
          return 'Отменен'
        default:
          return '-'
      }
    },
  },
}
</script>

<style>
#order-details-modal .modal-body{
  column-count: 1 !important;
}
</style>